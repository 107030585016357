<template>
	<teleport to="body">
		<div id="menu-bancard" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
			<div class="offcanvas-header container container-offcanvas">
				<h5 class="offcanvas-title" id="offcanvasBottomLabel">
					<span>Completá los siguientes datos</span>
					<span class="d-block text-sm fw-semibold text-muted">Con la seguridad de <b>Bancard</b>.</span>
				</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body container container-offcanvas pt-0" v-if="loading">
				<common-message-loading-small class="py-6 justify-content-center" text="Cargando tarjetas disponibles" />
			</div>
			<div class="offcanvas-body container container-offcanvas pt-0" v-else>
				<div class="list-group list-group-flush mb-2">
					<div class="list-group-item d-flex gap-3 align-items-center px-0" v-for="(card, index) in cards" v-bind:key="index">
						<div class="flex-fill lh-sm overflow-hidden">
							<div class="d-block text-sm fw-bold text-truncate">
								{{ card.brand }}
							</div>
							<div class="d-block text-sm text-truncate">
								<b>{{ (card.type == 'credit') ? 'Crédito' : ((card.type == 'debit') ? 'Débito' : 'Otra') }}</b>, ** <b class="font-code">{{ card.number }}</b>, hasta: <span class="fw-semibold text-heading">{{ card.expiration }}</span>
							</div>
						</div>
						<div class="hstack gap-2">
							<button type="button" class="btn btn-sm btn-square btn-danger" @click="cardsDestroy(card.token)" :disabled="submitting"><i class="bi bi-trash"></i></button>
							<button type="button" class="btn btn-sm btn-dark" @click="submit({ token: card.token, number: card.number })" :disabled="submitting">Usar</button>
						</div>
					</div>
					<div class="list-group-item p-0 text-sm" v-if="cards.length == 0">
						<common-message-error text="No se encontraron tarjetas cargadas." class="my-0" />
					</div>
				</div>
				<div class="d-grid gap-2 d-md-flex justify-content-md-end">
					<button type="button" class="btn btn-sm btn-neutral me-md-2" data-bs-dismiss="offcanvas">Cancelar</button>
					<button type="submit" class="btn btn-sm btn-dark" @click="modalRegister.modal.show()" :disabled="submitting">Registrar tarjeta</button>
				</div>
			</div>
		</div>
	</teleport>
	<cart-checkout-menu-bancard-register ref="modalRegister" :constraints="constraints" @success="cardsList" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableForm from '@/composables/form';

	export default {
		emits: ['setCard'],
		props: {
			constraints: Object
		},
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();
			const total = computed(() => store.getters['cart/total']);
			const modalRegister = ref(null);
			const loading = ref(true);
			const cards = ref([]);

			const cardsList = async () => {
				submitting.value = true;

				await StoreRepository.cardsList(store.getters['cart/slug'], { gateway: props.constraints.gateway, total: total.value })
					.then((response) => {
						cards.value = response.data.cards;
					}).catch((e) => catchError(e, true));

				loading.value = false;
				submitting.value = false;
			}

			const cardsDestroy = async (token) => {
				submitting.value = true;

				await StoreRepository.cardsDestroy(store.getters['cart/slug'], { gateway: props.constraints.gateway, token: token })
					.then(cardsList).catch((e) => catchError(e, true));

				submitting.value = false;
			}

			const submit = async (data) => {
				emit('setCard', {
					option: 'bancard',
					gateway: 'bancard',
					installments: 1,
					cardNumber: data.number,
					token: data.token
				});
			}

			onMounted(() => {
				document.getElementById('menu-bancard').addEventListener('shown.bs.offcanvas', async () => {
					cardsList();
				});
			});

			return { modalRegister, submitting, total, cards, loading, cardsDestroy, submit, cardsList };
		}
	}
</script>